import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/login";
import Layout from "@/Layout";
import { useRouteStore } from "@/stores/app";
import { getRouteByName } from "@/utils/handleRoute";

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/layout",
    component: Layout,
    redirect: "/baseData",
    children: [
      // {
      //   path: "/index",
      //   component: () => import("@/views/home/index.vue"),
      //   name: "homeIndex",
      //   meta: { title: "主页", icon: "" },
      // },
      {
        path: "/baseData",
        component: () => import("@/views/baseData/index.vue"),
        name: "baseData",
        meta: { title: "基础数据管理", icon: "" },
      },
      {
        path: "/userData",
        component: () => import("@/views/userData/index.vue"),
        name: "userData",
        meta: { title: "用户信息管理", icon: "" },
      },
      {
        path: "/wpsData",
        component: () => import("@/views/wpsData/index.vue"),
        name: "wpsData",
        meta: { title: "WPS信息管理", icon: "" },
      },
      // {
      //   path: "/dataDownload",
      //   component: () => import("@/views/dataDownload/index.vue"),
      //   name: "dataDownload",
      //   meta: { title: "数据下载", icon: "" },
      // },
      // {
      //   path: "/dataUpload",
      //   component: () => import("@/views/dataUpload/index.vue"),
      //   name: "dataUpload",
      //   meta: { title: "数据上传", icon: "" },
      // },
      {
        path: "/personalCenter",
        component: () => import("@/views/personalCenter/index.vue"),
        name: "personalCenter",
        meta: { title: "个人中心", icon: "" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to, from, next) => {
  const routeStore = useRouteStore();
  let result = routeStore.visitedRoutes.find((item) => item.name == to.name);
  if (!result && to.name) {
    routeStore.visitedRoutes.push(getRouteByName(to.name, router.getRoutes()));
  }
});

export default router;
