import request from "./index.js";

export function loginRequest(formData) {
  const profession = sessionStorage.getItem("profession");
  const data =
    profession === "pipeline"
      ? {
          strUser: formData.username,
          strPwd: formData.password,
        }
      : {
          phoneno: formData.username,
          password: formData.password,
        };
  return request({
    url: "login",
    method: "post",
    data: data,
  });
}
