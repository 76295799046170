<template>
  <RouterView />
</template>

<script setup>

</script>

<style>

</style>
